@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.multiInput {
  :nth-child(1n) .MuiInput-root {
    display: none;
  }

  :nth-child(1n) ul {
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.answerOptionItem {
  padding: 0.25rem 0.5rem 0 0 !important;

  :nth-child(1n) .MuiListItem-root {
    border-color: $dark-grey;
  }

  :nth-child(1n) .MuiListItemSecondaryAction-root {
    position: relative;
    transform: none;
    top: rem-calc(2);
    left: rem-calc(8);
  }
}

.excludedItem {
  :nth-child(1n) .MuiListItem-root {
    border-color: $medium-grey;
  }

  :nth-child(1n) .primary {
    color: $medium-grey;
  }

  :nth-child(1n) .icon {
    color: $medium-grey;
  }
}

.tagsSection {
  margin-bottom: 0.75rem;
}

.tagsCount {
  margin-bottom: rem-calc(4);
}

.tagsCountText {
  font-size: rem-calc(12);
  margin-left: rem-calc(4);
}
