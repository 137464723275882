@import "../../../../shared/src/sass/abstracts/variables";
@import "./common";

.photoWrapper {
  @include wrapper;
  flex-direction: row;
  background: $white;
  border: 1px solid $primary;
}

.galleryPhotoWrapper {
  @include galleryWrapper;
}

.photoAndCaptionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.photo {
  height: 72px;
  width: 103px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  object-fit: contain;
}

.galleryPhoto {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  .galleryDeleteButton {
    visibility: hidden; 
  }
  
}

.galleryPhoto:hover .galleryDeleteButton {
  visibility: visible;
}

.captionArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 12px;
}

.captionFirstLine {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.deleteButton {
  background: $white;
  border: none;
  cursor: pointer;
}

.galleryDeleteButton {
  position: absolute;
  top: 0;
  left: 147px;
  height: 2.75rem;
  width: 2.75rem;
  background-color: $black;
  opacity: 0.5;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  color: $white;

  &:hover {
    opacity: 0.75;
  }
}