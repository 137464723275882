@import "../../../../../../shared/src/sass/abstracts/variables";
@import "../../../../../../shared/src/sass/abstracts/functions";

.statusContainer {
  display: flex;

  div {
    width: rem-calc(15);
    height: rem-calc(15);
    margin: 0 rem-calc(10) 0 0;
    border-radius: 100%;
  }
}

.statusNotIncluded {
  border: 1px solid $dark-grey-blue;
  background-color: $grey-blue;
}

.statusIncluded {
  border: 1px solid $primary;
  background-color: $light-grey;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem-calc(15) 0;
}

.tableContainer {
  display: flex;
  flex-direction: column;
}

.filterContainer {
  display: flex;
}

.searchBarContainer {
  // display: flex;
  // align-items: center;
  // border: 1px solid $black;
  // border-radius: rem-calc(30);
  margin-bottom: 1rem;
  // padding: 0 rem-calc(10);
  // justify-content: space-between;
}

.confirmMessage {
  border: 1px solid $error;
  background-color: $error-fill;
  padding: 30px 25px;

  .undoneQuestion {
    padding-top: 15px;
    font-weight: 700;
  }
}
