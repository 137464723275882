@import "../../sass/abstracts/mixins";

.passwordRequirements {
  padding: 12px 15px 12px 10px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 5px;
    top: -28px;
    // z-index: -1;
    width: 15px;
    height: 15px;
    background-color: $white;
    border-right: 2px solid $light-grey;
    border-bottom: 2px solid $light-grey;
    transform: rotate(-135deg) translate(-14px, -7px);
  }

  .passwordRequirement {
    align-items: center;
    color: $error;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.15px;
    padding: 3px 0;

    &.fulfilled {
      color: $success;
    }
  }

  .title {
    color: $dark-grey;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 1.25px;
  }

  .progressRoot {
    height: 8px;
    margin-bottom: 7px;
    margin-top: 5px;
  }
  .progressBarColorPrimary {
    background: $success;
  }
}
