@import "../../../sass/abstracts/mixins";

.shareButtonsContainer {
  margin-top: 15px;
}

.shareButton {
  margin-top: 10px;
}

.fullWidth {
  width: 100%;

  &.disabled {
    background-color: $light-grey;
    cursor: "not-allowed";
  }
}
