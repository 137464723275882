@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.container {
  padding: 2rem 1rem;
}

.title {
  font-family: $font-family-body;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $black;
  font-size: 0.75rem;
  letter-spacing: 1.88pt;
  margin-bottom: 0.4rem;
}

.content {
  all: unset;
  color: $dark-grey-blue;
  font-size: 1rem;
  font-weight: $font-weight-regular;
  letter-spacing: 0.5pt;
  margin-bottom: 1.6rem;
  white-space: pre-wrap;
  &Editable {
    border: 1px solid $medium-grey;
  }
}

.button {
  width: rem-calc(150);
}
