@import "../../../sass/abstracts/mixins";
@import "../../../sass/abstracts/variables";

.td {
  color: $dark-grey;
  padding-right: rem-calc(16);

  &:first-child {
    padding-left: rem-calc(16);
  }
}
