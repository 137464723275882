@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/mixins";

$padding: calc(1rem - 2px) 1rem; // prevent size changes from added border

.sortContainer {
  display:flex;
  padding: $padding;
}

.sortLabelWrapper {
  margin: 0;
  align-items: center;
}

.sortLabel {
  color: $dark-grey-blue;
  font-size: 1rem;
  font-weight: $font-weight-regular;
  letter-spacing: 0.5pt;
  margin-right: 8px
}

.sortDropdown {
  color: $black;
  width: rem-calc(175);
  margin-right: 8px
}

.sortDirectionBox {
  @include reset-button;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-bottom: 4px;
  margin-top: 4px; 
  cursor: pointer;
  
  &Asc {
    transform: rotate(90deg);
  }

  &Desc {
    transform: rotate(90deg) scaleY(-1);
  }
}

.sortDirectionIcon {
  color: $white;
  font-size: 1.2rem;
}