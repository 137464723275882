@import "../../../../shared/src/sass/abstracts/functions";
@import "../../../../shared/src/sass/abstracts/variables";

.card {
  border: 1px solid $grey-blue;
  border-radius: 8px;
  padding: 16px;
  width: 295px;
  flex-grow: 1;
}

.cardTitle {
  font-size: rem-calc(24);
  font-weight: $font-weight-medium;
  margin-bottom: 1em;
}

.section {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid $light-grey;
  padding-bottom: 4;
  margin-bottom: 6;
}
.total {
  font-weight: 500;
  font-size: 18;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.chartWrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.pieChart {
  width: 128px;
  height: 128px;
}

.percentContainer {
  &.overlay {
    width: 100%;
    position: absolute;
    bottom: 25px;
    padding-right: 20px;
    pointer-events: none;
  }

  .percentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: rem-calc(24);
  }

  .percentWrapper:first-child {
    padding-bottom: 10px;
  }


  .percentage {
    font-size: rem-calc(36);
    font-weight: $font-weight-bold;
    color: $black;

    &.active {
        color: $primary;
    }

    &.submitted {
        color: $success;
    }

    &.complete {
        color: $warning;
    }
  }

  .status {
    color: $black; 
  }
}
