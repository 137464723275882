@import "shared/src/sass/abstracts/variables";

.navigationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3em;
  padding: 1em;
  border-top: 1px $light-grey solid;
  border-bottom: 1px $light-grey solid;
  position: fixed;
  z-index: 11;
  margin-top: 64px;
  width: 100%;
  background-color: $white;
}

.navigationChevron {
  text-decoration: none;
  color: $primary;
  font-size: 1.3em;
}

.dashboardLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: underline;
  color: $primary;
}

.navigationLink {
  display: flex;
  text-decoration: none;
  color: $primary;
}

.reviewActionBarContainer {
  position: fixed;
  z-index: 11;
  margin-top: 112px;
  width: 100%;
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 15px 35px;
  border-bottom: 1px $light-grey solid;
}

.document {
  height: 100%;
  width: 100%;
  max-width: 64em; //1024px
  margin: 195px auto 0;
  padding-left: 0.5em; //8px
  padding-right: 0.5em; //8px
}

.formActions {
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-bottom: 2.125rem;
  margin-bottom: 2.125rem;
}

.actionRequired {
  margin-top: 12px;
}

.button {
  margin-left: 20px;
}
