@import "../../../../../shared/src/sass/abstracts/functions";
@import "../../../../../shared/src/sass/abstracts/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $medium-grey;
  padding: rem-calc(24);
}

.label {
  color: $black;
  padding-bottom: 0.5rem;
}

.value {
  color: $dark-grey;
}
