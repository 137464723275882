@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.filterHeader {
  padding: rem-calc(20);
  margin: 0;
  border-bottom: 2px solid $light-grey;
  display: flex;
  flex-direction: column;
  outline: 0;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  border: 0;
  border-bottom: 1px solid $light-grey;
  width: 100%;
}

.filterOption {
  padding: rem-calc(8) rem-calc(24);
  margin: 0;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: $light-blue;
  }
}

.title {
  margin: 0 0 rem-calc(10);
  font-weight: $font-weight-regular;
  text-transform: capitalize;

  &.disabled {
    color: $grey-blue;
  }
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $dark-grey-blue;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;

  .icon {
    fill: $dark-grey-blue;
  }

  &.disabled {
    color: $grey-blue;
    cursor: text;
  }
}

.open {
  color: $primary;
  .icon {
    fill: $primary;
  }
}

.buttonContainer {
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    margin-bottom: 0.5rem;
  }
}
