@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
@import "mixins";

// Button Styles
.button {
  @include rowCenteredInline();
  @include mediumCapitalFont();
  @include buttonTransitions();
  background-color: $primary;
  border-radius: rem-calc(4);
  border: none;
  box-shadow: $drop-shadow, 0 0 0 rem-calc(6) transparent;
  color: $white;
  padding: rem-calc(13) rem-calc(24);
  position: relative;
  cursor: pointer;
}

.buttonChecked {
  background-color: $secondary;
}

.buttonCompleted {
  background-color: $success;
}

.buttonDisabled {
  background-color: $light-grey;
  color: $dark-grey-blue;
  cursor: not-allowed;
}

.buttonEnabled {
  &:hover {
    background: $secondary;
  }
}

.buttonLoading {
  background-color: $light-grey;
  cursor: not-allowed;
}

.buttonWithoutMouseFocus {
  &:focus {
    box-shadow: $drop-shadow, 0 0 0 rem-calc(4) $focus-indicator-outline;
  }
}
.retainer {
  @include rowCentered;
  min-height: rem-calc(24);
  min-width: rem-calc(64);
}

//Circle Icon Styles
.circledIcon {
  @include circledIconSize;
  position: relative;
  top: rem-calc(-4);
}

.iconCircle {
  @include iconCircleBorder();
  @include iconCircleSize();
  border-color: $white;
}

.iconCircleBeforeLabel {
  margin-right: rem-calc(8);
}

// Progress Styles
.progress {
  color: $white;
  padding: 0;
}

// Generic Label Styles
.labelChecked {
  @include screenReaderOnly;
}

.labelLoading {
  @include screenReaderOnly;
}
