@import "../../../sass/abstracts/mixins";

.navbarWrapper {
  display: flex;
  flex-direction: row;
  height: 88vh;
  overflow: hidden;

  @media (min-width: $min-desktop) {
    margin-top: 72px;
  }

  @media (max-width: $max-tablet) {
    margin-top: 35px;
  }

  .navHeader {
    margin: 1rem 0 0.5rem 1rem;
    display: block;
    color: $dark-grey;
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-align: left;
    text-transform: uppercase;
  }
}

.mainContent {
  flex: 1;
  overflow-x: auto;
}
