@import "shared/src/sass/abstracts/functions";
@import "shared/src/sass/abstracts/variables";

.locationMapPinIcon {
    display: flex;
    background-color: $light-grey;
    border-radius: rem-calc(4);
    width: rem-calc(34);
    margin-right: rem-calc(27);
    padding: rem-calc(4);
    cursor: default !important;
}