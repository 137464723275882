@import "../../../sass/abstracts/variables";

.tabsContainer {
  display: flex;
}

.tab {
  display: flex;
  flex-direction: column;
}

.tabList {
  display: flex;
  flex-direction: column;
}

.whiteTabList {
  display: flex;
  border-bottom: 2px solid $light-grey;
}
