@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.disabled {
  cursor: not-allowed;
}

.enabled {
  cursor: pointer;
}

.addIcon {
  color: $primary;
  font-weight: 600;
  position: absolute;
  right: rem-calc(4);
}

.formItem {
  position: relative;
  background-color: $white;
  display: flex;
  align-items: center;
  border: 1px solid $light-grey;
  color: $dark-grey;
  border-radius: 4px;
  font-size: remCalc(14);
  margin-bottom: 0.5rem;
  opacity: 1;
  padding: rem-calc(6) 1rem;
  width: 100%;
  
  &:hover {
    background: $active-fill;
  }
}

.formItemIcon {
  margin-right: 0.25rem,
}

.formItemLoader {
  padding: 0;
  width: auto;
  margin-left: auto;
}