@import "../../sass/abstracts/mixins";

.multiInputContent {
  border-radius: rem-calc(4);
  border: 1px solid $dark-grey;
  background: $background;
  border-radius: 0.25rem;

  &.error {
    border-color: $error;
    background: $error-fill;
  }

  &.disabled {
    background-color: $light-grey;
  }
}

.inputWrapper {
  border: none !important;
  padding: rem-calc(6) rem-calc(4);
  
  &.disabled {
    background-color: $light-grey;
  }
}

.input {
  background-color: transparent !important;
}

.multiInputList {
  padding: rem-calc(12);
  margin: 0;
}

.multiInputListItem {
  list-style-type: none;
  display: flex;
  align-items: center;
  border: 1px solid $dark-grey;
  border-radius: rem-calc(4);
  justify-content: space-between;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: default;
}

.endAdornment {
  cursor: pointer;

  & i {
    position: relative;
    top: 2px;
  }
}
