@import "../../sass/abstracts/mixins";

.endAdornment {
  bottom: 12px;
  top: unset !important;
}

.listbox {
  padding: 0 !important;
}

.noOptions {
  @include font-body-regular;

  color: $dark-grey;
}

.option {
  @include font-body-regular;

  color: $dark-grey;
  padding: 8px !important;

  &[aria-selected="true"] {
    background-color: $light-grey !important;
    color: $black;
  }

  &[data-focus="true"] {
    background-color: $light-blue !important;
    color: $black;
  }
}

.paper {
  border-color: $light-grey;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none !important;
}

.popupIndicator {
  fill: $black;

  &Disabled {
    fill: $dark-grey-blue;
  }
}

.selectionList {
  width: 100%;
  padding-bottom: 8px;
}
