@import "../../../../sass/abstracts/mixins";

.ratings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: $max-phone) {
    flex-direction: column;
  }
}

.labelContainer {
  flex: 1;
  padding-right: 1rem;

  @media (max-width: $max-phone) {
    margin-bottom: rem-calc(8);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    width: 100%;
  }
}

.label {
  font-size: rem-calc(24);
  font-weight: 400;

  @media (max-width: $max-phone) {
    margin-bottom: 0;
    font-size: rem-calc(20);
  }
}

.ratingContainer {
  flex: 3;
}

.addCommentBtn {
  display: flex;
  border: 1px solid;
  background-color: $white;
  border-radius: 0.25rem;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  height: 3.5rem;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: $max-phone) {
    font-size: 1rem;
    background-color: $white;
    min-height: 0;
    min-width: 0;
  }

  .accessory {
    align-self: center;
    font-weight: bold;
    padding-right: 5px;
  }
}

.comment {
  width: 100%;
  padding-left: 0;

  @media (max-width: $max-tablet) {
    max-width: rem-calc(470);
    align-self: flex-end;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
