@import "../../../../../shared/src/sass/abstracts/functions";
@import "../../../../../shared/src/sass/abstracts/variables";

.document {
  height: 100%;
  width: 100%;
  max-width: 64em; //1024px
  margin: 0 auto;
  padding-left: 0.5em; //8px
  padding-right: 0.5em; //8px
}

.substationWrapper {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $medium-grey;
  padding: rem-calc(24);
  align-items: center;
} 

.substationRowWrapper {
  display: flex;
  flex-direction: column;
}

.substationNameWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.substationName {
  color: $black;
}

.substationDistance {
  color: $dark-grey;
}
