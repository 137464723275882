@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/functions";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.paper {
  min-width: 300px;
  padding: 0px;
  box-shadow: $backdrop-drop-shadow !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
  z-index: 1200;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  outline: 0;
  background-color: $white;

  @media (min-width: $min-desktop) {
    width: 30vw;
  }
}

.left {
  left: 0;
  right: auto;
  outline: 100vw solid $backdrop-color;
}

.right {
  left: auto;
  right: 0;
  outline: 100vw solid $backdrop-color;
}

.top {
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  height: auto;
  max-height: 100%;
  outline: 100vh solid $backdrop-color;
}

.bottom {
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  max-height: 100%;
  outline: 100vh solid $backdrop-color;
}

.content {
  min-height: calc(100% - 20px);
  margin-bottom: 20px;
}

.disableBackdrop {
  outline: none;
}
