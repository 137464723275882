@import "shared/src/sass/abstracts/variables";

.button {
  display: flex;
  justify-content: space-between;
}

.chevronUp {
  align-self: center;
  font-weight: bold;
  margin-right: 6px;
}

.chevronDown {
  align-self: center;
  font-weight: bold;
}

.arrow {
  border-bottom: 1px solid rgba($primary, 1);
  border-left: 1px solid $white;
  border-right: 1px solid rgba($primary, 1);
  border-top: 1px solid $white;
  height: 6px;
  width: 6px;
  position: relative;
  top: 22px;
  right: 12px;
  transform: rotate(45deg);
  background-color: $white;
}
