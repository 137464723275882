@import "../../sass/abstracts/variables";

.loader {
  width: 100%;
}

.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  background: rgba($white, 0.45);
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.progress {
  color: $primary;
}

.secondaryProgress {
  color: $white;
}
