@import "../../../../shared/src/sass/abstracts/mixins";

.circle {
  display: flex;
  background-color: $white;
  border: none;
  width: rem-calc(63);
  height: rem-calc(63);
  border-radius: 50%;
  box-shadow: 1px 2px 2px rgba($primary, 0.2);
  justify-content: center;
}

.placeMarker {
  font-size: 1.5em;
  margin: auto;
}

.markerContainer {
  position: relative;
  z-index: 9;
}

.loading {
  text-align: center;
}

.hidden {
  position: absolute;
}

.dragging,
.hidden {
  z-index: 11;
}

.bottomLeftContainer {
  position: absolute;
  left: 1em;
  bottom: 1em;
  z-index: 10;

  .markerControlContainer {  
    display: flex;
    width: 8.875em;
    justify-content: space-between;
    padding-top: 10px;
  }
}

.pin {
  height: 30px;
  cursor: pointer;
  stroke: none;
}

.bottomRightContainer {
  display: flex;
  position: absolute;
  right: rem-calc(16);
  bottom: rem-calc(16);  
  z-index: 10;

  .zoomContainer {
    display: flex;
    width: rem-calc(180);
    height: rem-calc(60);
    background-color: $background;
    box-shadow: 1px 2px 2px rgba($primary, 0.2);
    border-radius: 4px;

    button:last-child {
      border-right: none;
    }

    button:not(:last-child) {
      border-right: 2px solid $light-grey;
    }

    @media (max-width: $max-phone) {
      top: rem-calc(16);
    }
  }
}

.controlButton {
  display: inline-block;
  align-self: center;
  background: $background;
  font-size: rem-calc(20);
  width: 3.75rem;
  border-top: none;
  border-left: none;
  border-bottom: none;
  height: rem-calc(45);
}

.satelliteImg {
  border-radius: 4px;
  width: 2.25em;
}
