@import "../../../../../shared/src/sass/abstracts/variables";
@import "../../../../../shared/src/sass/abstracts/functions";

/* Character Styles */

.bodyLarge {
  font-size: rem-calc(20);
}

.sectionDescription {
  letter-spacing: rem-calc(2);
  color: $dark-grey;
  text-transform: uppercase;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: rem-calc(26);
  margin-top: 1rem;

  .flexColumn {
    display: flex;
    flex-direction: column;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.chartContainer {
  width: 46px;
  height: 46px;
  border: 1px solid #0000001f;
  border-radius: 200px;
  margin-left: rem-calc(7);
}

.editButton {
  margin-right: 0.5rem;
}
