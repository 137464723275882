@import "../../../../shared/src/sass/abstracts/mixins";

.searchBarContainer {
  margin: 24px 0;
    .searchBar {
      margin-top: 0.5rem;
      border: 1px solid $medium-grey !important;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-right: 1rem;
      height: auto;
      border-radius: 1.5rem !important;
    }
}

.noEvents {
  text-align: center;
  color: $black;
  padding: 2rem 1rem;

  .label {
    @include font-medium-uppercase;
    color: $dark-grey-blue;
  }

  .noEventsIcon {
    font-size: 3rem;
    color: $grey-blue;
    position: relative;
  }
}
