@import "../../sass/abstracts/mixins";

$container-width: 348px;

@mixin logo {
  img {
    max-width: $container-width;
    height: auto;
  }
}

.login {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  .container {
    width: $container-width;
  }
}

.imgVersion {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include logo;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  form > * {
    margin-top: 16px;
  }

  form {
    width: 100%;

    input {
      width: auto !important;
    }
  }
}

.adfsButton,
.submitButton {
  width: 100%;
  margin-top: 16px;
}

.logo {
  width: 100%;
}

.version {
  display: block;
  text-align: center;
  letter-spacing: 0.1em;
  color: $dark-grey;
  margin-top: 12px;
  margin-bottom: 48px;
}

.welcome {
  display: block;
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 2rem;
}

.resetPasswordBubble {
  background-color: $error-fill;
  border-radius: 4px;
  border: 1px solid $error;
  color: $dark-grey;
  font-size: 0.875rem;
  letter-spacing: 0.44px;
  line-height: 1rem;
  margin-bottom: 1rem;
  padding: 15px 35px;
  position: relative;
  text-align: left;

  .icon {
    font-size: 1rem;
    left: 12px;
    position: absolute;
    top: 15px;
  }

  .actionLink {
    color: $primary;
    cursor: pointer;
    display: block;
    text-decoration: underline;
  }
}

.needHelp {
  margin-top: 30px;
}

.error {
  color: $error;
  display: block;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.helpLink {
  display: block;
}

.showPasswordLink {
  color: $primary;
  font-size: rem-calc(14);
  position: relative;
  top: -1px;
}

.passwordReset,
.passwordHelp {
  display: flex;
  flex-direction: column;
  margin: 2.25em auto;
  max-width: $container-width;
  align-items: center;
  @include logo;

  .title {
    display: block;
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 46px;

    @media (min-width: $min-desktop) {
      text-align: center;
    }

    @media (max-width: $max-tablet) {
      text-align: left;
    }
  }

  .form {
    width: $container-width;
  }

  .message {
    display: block;
    margin-bottom: 9px;
  }
  .mobileHeader {
    align-items: center;
    box-shadow: $black 0 1px 3px;
    color: $black;
    display: flex;
    font-size: 1.25rem;
    font-weight: 500;
    height: 56px;
    justify-content: center;
    letter-spacing: 0.26px;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;

    .chevron {
      color: $dark-grey;
      font-size: 1.5rem;
      left: 15px;
      position: absolute;
      top: 28%;
    }
  }

  .submitButton {
    margin-top: 16px;
    width: 100%;

    &.success {
      background-color: $success;
      color: $white;
    }
  }

  .returnLink {
    margin-top: 24px;
    padding: 0 8px;
    text-align: center;
  }
}

.activation {
  display: flex;
  flex-direction: column;
  margin: auto auto;
  max-width: $container-width;
  min-width: 359px;
  align-items: center;
  @include logo;

  .header {
    color: $black;
    font-size: 2.125rem;
    font-weight: 400;
    margin: 0 0 37px 0;
    text-align: center;
  }
}

.splashScreen {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/png/splash_141227807.png");

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -150px;
    width: 400px;
    background: linear-gradient(
      120.79deg,
      rgba(225, 225, 225, 0.8) 9.38%,
      rgba(255, 255, 255, 0.104) 95.55%
    );
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .logo {
    margin-bottom: 40px;
    width: 250px;
    height: auto;
  }

  .status {
    color: $white;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.15px;
  }
}

.validationError {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
}
