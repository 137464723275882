@import "../../../../shared/src/sass/abstracts/mixins";

.indicatorWrapper {
  display: inline-flex;
  width: 16px;
  vertical-align: text-top;
  margin-top: 1px;
}

.indicator {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;

  &.UNASSIGNED {
    border-color: #214467;
    background-color: #D4E6F7;
  }
  &.IN_REVIEW {
    border-color: #006C55;
    background-color: #4AAB96;
  }
  &.ASSIGNED {
    border-color: #214467;
    background-color: #3085D8;
  }
  &.ASSESSOR_COMPLETE {
    border-color: #006C55;
    background-color: #D0E3DF;
  }
  &.IN_PROGRESS {
    border-color: #000000;
    background-color: #214467;
  }
  &.STORM_CENTER_VERIFIED {
    border-color: #00853c;
    background-color: #006C55;
  }
  &.CANCELLED {
    border-color: #000000;
    background-color: #919191;
  }
  &.ACTION_REQUIRED {
    border-color: #c8102e;
    background-color: #fae6e9;
  }
}
