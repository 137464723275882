@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
.navButton {
  &:hover {
    background-color: $active-fill;
  }
  @include focus-indicator-disappear;
  border: none;
  border-radius: rem-calc(4);
  background-color: transparent;
  color: $primary;
  padding: 0;
  height: rem-calc(24);
  width: rem-calc(24);
}
.navButtonKeyboardFocused {
  &:focus {
    @include focus-indicator-appear;
  }
}

.backIcon {
  fill: $primary;
  height: 100%;
  width: 100%;
}

.forwardIcon {
  fill: $primary;
  height: 100%;
  transform: scaleX(-1);
  width: 100%;
}
