@import "../../sass/abstracts/mixins";

.aside {
  @include ellipsize-text;
  @include font-body-regular;

  color: $dark-grey;
}

.details {
  min-width: 0;
  padding-right: 8px;
}

.subtitle {
  @include ellipsize-text;
  @include font-body-regular;

  color: $dark-grey;
}

.title {
  @include ellipsize-text;
  @include font-body-regular;

  color: $black;
}
