@import "../../../sass/abstracts/mixins";

.navigationItem {
  color: $dark-grey;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.active {
    background-color: $active-fill;
    border-radius: 0px 4px 4px 0px;
    color: $black;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
}

.subNavigationItem {
  margin-left: 0.75rem;
}

.navigationItemIcon {
  font-size: 1.25rem !important;
  margin: 18px 30px 18px 15px;
}

.navigationItemLink {
  font-size: 0.875rem !important;
}
