@import "../../../sass/abstracts/mixins";

$underline-width: 2px;

@mixin base-tab-style {
  @include focus-overlay;
  @include font-medium-uppercase;
  @include reset-button;

  padding: 16px 24px;
  position: relative;
}

@mixin focus-overlay {
  &::after {
    @include focus-indicator-disappear;
    border-radius: 4px;
    bottom: -$underline-width;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.tab {
  @include base-tab-style;

  background-color: $primary;
  color: $grey-blue;

  &:hover {
    border-bottom: $underline-width solid $grey-blue;
    color: $white;
    padding-bottom: 16px - $underline-width;
  }

  &KeyboardFocused {
    &:focus {
      color: $white;
      @include focus-indicator-appear-inset-external-focus;
    }
  }

  &Selected {
    border-bottom: $underline-width solid $white;
    color: $white;
    padding-bottom: 14px;
  }
}

.whiteTab {
  @include base-tab-style;
  cursor: pointer;

  &KeyboardFocused {
    &:focus {
      color: $primary;
      @include focus-indicator-appear-inset-external-focus;
    }
  }

  &Selected {
    border-bottom: $underline-width solid $primary;
    color: $primary;
    padding-bottom: 1rem;
  }
}
