@import "../../sass/abstracts/mixins";

.backButton {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin-right: 1rem;

  .icon {
    font-size: 1.5rem;
  }
}

.body {
  // Ellipsis on resize
  overflow: hidden;
}

.title {
  margin: 0;
  color: $black;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.26px;
  // Ellipsis
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrapper {
  background-color: $white;
  box-shadow: 0px 1px 3px $light-grey;
  line-height: 1.5rem;
  padding-bottom: 1.05rem;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 1.05rem;
  position: sticky;
  display: flex;
  align-items: center;
}

.deleteWrapper {
  cursor: pointer;
  right: 15px;
  position: absolute;
  top: 17px;
  .icon {
    font-size: 1.5rem;
  }
}
