@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.a {
  color: $primary;
  font-size: rem-calc(14);
  line-height: rem-calc(22);
}

.button {
  background: none;
  border: none;
  border-radius: rem-calc(4);
  box-shadow: 0 0 0 rem-calc(6) transparent;
  color: $primary;
  cursor: pointer;
  font-size: rem-calc(16);
  line-height: rem-calc(24);
  padding: 0 rem-calc(8) 0 rem-calc(8);
  text-decoration: underline;
  @include focus-indicator-appear(box-shadow);
  &:focus {
    box-shadow: 0 0 0 rem-calc(4) $yellow;
  }
  &:hover {
    color: $secondary;
  }
}

.buttonMouseFocused {
  &:focus {
    box-shadow: 0;
  }
}

.span {
  color: $primary;
  display: block;
  font-size: rem-calc(14);
}

.label {
  color: $black;
  font-size: rem-calc(20);
  font-weight: 500;

  &.smallLabel {
    color: $dark-grey;
    margin-bottom: rem-calc(5);
    font-size: rem-calc(13);
  }
}

.wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
  margin-top: 0.75rem;

  &.noMargin {
    margin-top: 0;
  }
}

.withAssistiveLink {
  justify-content: space-between;
}

.required {
  color: $error;
}
