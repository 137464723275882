@import "../../sass/abstracts/mixins";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media print {
    display: none;
  }
}

.pageTitle {
  font-weight: $font-weight-bold;
  font-size: rem-calc(36);
  letter-spacing: rem-calc(0.63);
  margin: 0;
}
