@import "../../../../shared/src/sass/abstracts/mixins";

.wrapper {
  text-align: center;
  color: $black;
  padding: 2rem 1rem;
}

.clearButton {
  @include font-medium-uppercase;
  color: $error;
  background: none;

  &:hover {
    background: none;
    text-decoration: underline;
  }
}

.label {
  @include font-medium-uppercase;
  color: $dark-grey-blue;
}

.nothingFoundIcon {
  font-size: 3rem;
  color: $dark-grey-blue;
  position: relative;
  left: -0.25rem;
}
