@import "../../../../shared/src/sass/abstracts/mixins";

.viewEventLink {
  display: flex;
  align-items: center;

  .icon {
    font-size: rem-calc(20);
    color: $primary;
  }
}

.tableContainer {
  position: relative; // to contain the Loader
}
