@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/functions";

.contentWrapper {
  overflow-y: auto;
  position: relative;
  height: auto;
  max-height: 200px;
}

// hides unused input
.hideInput {
  display: none;
}

.textInputWrapper {
  padding: rem-calc(5);
}

// copied from TextInput.module.scss
.input {
  font-family: "Roboto", sans-serif;
  border: none;
  font-size: 1rem;
  background-color: inherit;
  resize: none;

  &::placeholder {
    color: $dark-grey-blue;
  }
  &:focus {
    border: none;
    outline: none;
  }
  &:focus-visible {
    border: none;
    outline: none;
  }
  &:active {
    border: none;
    outline: none;
  }
}

.pillWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pill {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: $light-blue;
  border: 1px solid $dark-grey-blue;
  border-radius: 4px;
  margin: 4px 4px;
  align-items: center;
  justify-content: center;
  color: $dark-grey-blue;
}

.pillText {
  margin-left: 4px;
  line-height: 16px;
  font-size: 14px;
  margin: 8px 8px;
}

.pillIcon {
  margin-right: 12px;
}

.suggestionsWrapper {
  margin-top: 6px;
  overflow-y: auto;
  position: relative;
  max-height: 300px;
}

.suggestionsLabel {
  font-size: 12px;
  color: $dark-grey-blue;
}

.modalContentWrapper {
  /* Shows scrollbar */
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: 7px; /* Firefox */

  ::-webkit-scrollbar {
    //   /* for Chrome; Safari and Opera */
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.modalPromptTitle {
  color: $black;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 18px;
}

.modalButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 48px 0 0 0;
}

.buttonMargin {
  margin: 0 24px;
}
