@import "shared/src/sass/abstracts/mixins";

.searchBarContainer {
  margin-bottom: 1rem;

  .searchBar {
    margin-top: 0.5rem;
    border: 1px solid $medium-grey !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    height: auto;
    border-radius: 1.5rem !important;
  }
}

.assessorListItem {
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: space-between;
}
