@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/functions";
@import "../../../sass/abstracts/mixins";

.error {
  border: 2px solid $error;
  padding: rem-calc(16);
  border-radius: rem-calc(4);
  background: $error-fill;
}
