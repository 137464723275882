@import "shared/src/sass/abstracts/mixins";

.wrapper {
  margin-bottom: rem-calc(21);
}

.map {
  height: rem-calc(250);
  width: "100%";
}

.buttonContainer {
  display: flex;
  height: rem-calc(60);
  border-radius: 4px;
  margin-bottom: -10px;
}
