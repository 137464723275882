@import "shared/src/sass/abstracts/variables";

.container { 
  padding-bottom: 50px;
  padding-top: 20px;
}

.substation {
  margin-top: 15px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $light-grey;
  color: $dark-grey-blue;
}

.bold {
  font-weight: 700,
}

.rightAlign {
  text-align: right;
}
