@import "./../../sass/abstracts/variables";
@import "./../../sass/abstracts/functions";
@import "./../../sass/abstracts/mixins";

.link {
  @include focus-indicator-appear;
  @include focus-indicator-disappear;

  border-radius: 4px;
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: $secondary;
  }

  &:visited {
    color: $visited;
  }
}

.linkDisabled {
  color: $dark-grey-blue !important;
}
