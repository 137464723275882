@import "../../../../../shared/src/sass/abstracts/mixins";

.appliedFilters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.iconContainer {
  display: flex;
}

.filterPill {
  background: $light-blue;
  padding: rem-calc(7);
  border: 1px solid $dark-grey-blue;
  display: inline-flex;
  border-radius: rem-calc(4);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: rem-calc(10);
  margin-right: 0.5rem;

  span {
    text-transform: capitalize;
  }
  .icon {
    margin-left: rem-calc(5);
    color: $dark-grey-blue;
  }
}

.filterMessage {
  display: flex;
  padding-bottom: 10px;
}

.assessmentList {
    .filterMessage {
        background-color: red;
    }
}