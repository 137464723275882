@import "shared/src/sass/abstracts/mixins";

.statusContainer {
  display: flex;
  align-items: center;
  width: 178px;

  .status {
    padding-left: 0.5rem;
  }
}

.assignBtn {
  color: $primary;
  background: none;
  padding: 0;
  box-shadow: none;

  &:hover {
    background: none;
    cursor: pointer;
    text-decoration: underline;
  }
}

.actionBtnsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;

  @media print {
    margin: 50px 0 0;

    h2: {
      margin: 0;
    }
  }

  button {
    margin-left: 0.5rem;

    @media print {
      display: none;
    }
  }
}

@media print {
  .noPrint {
    display: none;
  }
}