@import "../../../../../../shared/src/sass/abstracts/mixins";

.assessmentHistoryTable {
  padding: 0;

  table {
    width: 100%;

    th,
    td {
      padding: 0 4px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        text-align: right;
        padding-right: 0;
      }

      &.cellEntryItem {
        vertical-align: text-top;
        padding-top: 15px;
      }
    }

    tr {
      border-bottom-width: 2px;
    }
  }

  .historyDateCell {
    display: flex;
    flex-direction: column;

    & span {
      line-height: rem-calc(24);
    }
  }

  a {
    text-decoration: underline;
  }

  .noData,
  .time {
    color: $dark-grey-blue;
  }

  .noData {
    padding-left: 16px;
  }
}

.assessmentContainer {
  display: flex;
  flex-direction: column;
}
