@import "../../sass/abstracts/mixins";

@include media-query-min-ipad {
  .drawer {
    width: 500px;
  }
}

@include media-query-max-phone {
  .drawer {
    padding-top: 16px;
  }

  .form {
    overflow: scroll;
  }
}
