@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.button {
  border: none;
  background: none;
  padding: 15px;
}

.label {
  color: $error;
  font-size: rem-calc(18);
  font-weight: 500;
}
