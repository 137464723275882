@import "../../sass/abstracts/mixins";

.linkBtn {
  font-size: 1rem;
  background: none;
  border: none;
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

.iconBtn {
  font-size: 1.5rem;
  padding: 0;
  background: none;
  border: none;
  color: $primary;
  display: flex;
  align-items: center;
}
