@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/functions";

.menu {
  width: 100%;
}

.menuItem {
  background: none;
  border: none;
  display: flex;
  padding: 19px 53px 19px 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-grey;

  &:hover {
    cursor: pointer;
  }
}

.label {
  font-size: rem-calc(14);
}

.chevronRight {
  transform: rotate(180deg);
  font-size: 1.35rem;
}

.pencil {
  font-size: 1.25rem;
}

.phoneSettings {
  font-size: 1.35rem;
}

.icon {
  display: inline-block;
  margin-right: 20px;
}

.left {
  display: flex;
}

.menuDivider {
  background-color: $light-white;
}

.dividerTitle {
  font-size: remCalc(12);
  padding: 24px 16px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: remCalc(1.88);
}
