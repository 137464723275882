@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/mixins";
.list {
  display: flex;
  flex-direction: column;
}

.listButton {
  border: none;
  font-size: 0.75rem;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.disabled {
  border: none;
  font-size: 0.75rem;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  cursor:not-allowed;
}
