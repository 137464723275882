@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";

@mixin circledIconSize {
  font-size: rem-calc(14);
  height: rem-calc(10);
  width: rem-calc(10);
}

@mixin iconCircleBorder {
  border-radius: 50%;
  border-style: solid;
  border-width: rem-calc(2);
}

@mixin iconCircleSize {
  height: rem-calc(18);
  width: rem-calc(18);
}

@mixin rowCentered {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}

@mixin rowCenteredInline {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}

$duration: 200ms;
$timingFunction: cubic-bezier(0.4, 0, 0.2, 1);

@mixin buttonTransitions {
  transition: background-color $duration $timingFunction,
    border-color $duration $timingFunction, box-shadow $duration $timingFunction;
}

@mixin screenReaderOnly {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin mediumCapitalFont {
  font-size: rem-calc(16);
  font-weight: 500;
  letter-spacing: rem-calc(1.44);
  line-height: 1.5;
  text-transform: uppercase;
}
