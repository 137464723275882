@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
.datePicker {
  display: flex;
  flex-direction: column;
  padding: rem-calc(16);
}
.header {
  display: flex;
  padding-bottom: rem-calc(16);
}
.heading {
  font-weight: $font-weight-medium;
  color: $black;
  flex-grow: 1;
  margin: 0;
  text-align: center;
}
