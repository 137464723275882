@import "../../../sass/abstracts/mixins";

.tableHeader {
  @include font-medium-uppercase;
  color: $dark-grey-blue;
  padding-bottom: rem-calc(16);
  padding-right: rem-calc(16);
  padding-top: rem-calc(16);
  text-align: left;

  &:first-child {
    padding-left: rem-calc(16);
  }
}
