@import "../../sass/abstracts/mixins";

$border-width: 1px;

.icon {
  fill: $dark-grey-blue;
  height: 16px;
  transform: scale(1.5);
  width: 16px;
}

.label {
  @include ellipsize-text;

  padding-right: 8px;
}

.selectionChip {
  @include focus-indicator-disappear($border-width);
  @include font-body-regular;
  @include reset-button;

  align-items: center;
  background-color: $white;
  border: $border-width solid $dark-grey;
  border-radius: 4px;
  color: $black;
  display: flex;
  justify-content: space-between;
  padding: 5px 8px;
  user-select: none;
  width: 100%;

  &:hover {
    background-color: $light-blue;
  }

  &KeyboardFocused {
    @include focus-indicator-appear($border-width);
  }
}
