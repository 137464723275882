@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.spaceBetween {
  justify-content: space-between;
}

.centerFlex {
  display: flex;
  align-items: center;
}

.formBuilder {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  background-color: $light-white;
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.PortalTabOpen {
    height: auto;
  }
}

.tabsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tabList {
  flex: 0;
}

.tabsContent {
  flex: 1;
  overflow: hidden;
}

.tabPanel {
  height: 100%;
}

// Layout
.contentWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  flex-shrink: 1;
  padding: rem-calc(40);

  /* Shows scrollbar */
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: 7px; /* Firefox */

  ::-webkit-scrollbar {
    //   /* for Chrome; Safari and Opera */
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.contentContainer {
  // display: flex;
  // flex-direction: column;
  max-width: rem-calc(656);
  flex-shrink: 1;
}

/* styles are used in conjunction with sidebar styles */
.propertiesSidebar {
  height: 100%;
  padding-top: rem-calc(16);
  width: rem-calc(378);
  // position set to fixed with top: 160 in sidebar styles
  right: 0;
}

.alert {
  background: $warning-fill;
  border: 1px solid $warning;
  border-radius: 4px;
  padding: 16px 12px;
  margin: 16px 0;
}

.alertTitle {
  font-size: rem-calc(12);
  font-weight: 500;
  color: $black;
  letter-spacing: rem-calc(1.88);
  margin-bottom: rem-calc(8);
}

.alertBody {
  font-size: rem-calc(14);
}

.alertButton {
  text-transform: uppercase;
  padding: 0 16px;
  display: block;
}

.lowercase {
  text-transform: initial;
}

.publishedBanner {
  background: rgba(0, 133, 60, 0.8);
  border: 1px solid $success;
  color: white;
  padding: rem-calc(14);
  border-radius: rem-calc(4);
  margin-bottom: rem-calc(8);
}

.deactivatedBanner {
  background: $error;
  border: 1px solid $error;
  color: white;
  padding: rem-calc(14);
  border-radius: rem-calc(4);
  margin-bottom: rem-calc(8);
}

.tabPortal {
  background-color: $light-white;
  overflow: hidden;
}
