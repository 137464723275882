@import "../../sass/abstracts/mixins";

$border-width: 1px;

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.visibilitySensor {
  height: 0.5rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  background-color: white;
}

.list {
  @include reset-list;

  max-height: 500px;
  overflow-x: scroll;
  padding-bottom: 4px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 4px;
}

.listItem {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.listTitle {
  @include font-medium-uppercase;

  color: $dark-grey;
  margin-top: 24px;
}

.loader {
  flex-grow: 1;
  overflow-y: auto;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 13px;
}

.searchBar {
  margin-top: 0.5rem;
  border: 1px solid $medium-grey !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  height: auto;
  border-radius: 1.5rem !important;
}

.error {
  margin: 0.5rem 0;
}

.tryAgain {
  margin-left: 0.5rem;
}

.buttonContent {
  display: flex;
  align-items: center;
}

.selectedCount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0 4px;
  border-radius: 50%;
  background: $white;
  color: $primary;
}
