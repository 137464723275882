@import "../../../../shared/src/sass/abstracts/variables";
@import "./common";

.photoTarget {
  @include wrapper;
  background: $white;
  border: 1px dashed $primary;
  justify-content: space-around;
}

.photoGalleryTarget {
  @include galleryWrapper;
  background: $light-blue;
  border: 1px dashed $primary;
  cursor: pointer;
}

.photoIcons {
  color: $primary;
  margin-right: 8px;
}

.cameraIcon {
  color: $primary;
  margin-bottom: 8px;
}

.dragAndDropArea {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: $primary;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 1.44px;
  text-transform: uppercase;
  
  &.addToGallery {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

// ------------- Photo Drag & Drop Background Effects ------------- //
// @property is currently only supported on Edge 85+, Chrome 85+, and Opera 71+
// https://developer.mozilla.org/en-US/docs/Web/CSS/@property

// hover property rules
@property --hoverA {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}
@property --hoverB {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}
@property --hoverC {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}
@property --hoverD {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}
@property --hoverE {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}
// drop property rules
@property --dropA {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 70%;
}
@property --dropB {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 50%;
}
@property --dropC {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 90%;
}
@property --dropD {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 85%;
}

.photoTargetHover {
  background-image: -webkit-radial-gradient(circle at 50%, rgb(202, 226, 245) var(--hoverA), $active-fill var(--hoverB), $active-fill var(--hoverC), $light-blue var(--hoverD), $white var(--hoverE));
  background-image: -o-radial-gradient(circle at 50%, rgb(202, 226, 245) var(--hoverA), $active-fill var(--hoverB), $active-fill var(--hoverC), $light-blue var(--hoverD), $white var(--hoverE));
  background-image: -moz-radial-gradient(circle at 50%, rgb(202, 226, 245) var(--hoverA), $active-fill var(--hoverB), $active-fill var(--hoverC), $light-blue var(--hoverD), $white var(--hoverE));
  background-image: radial-gradient(circle at 50%, rgb(202, 226, 245) var(--hoverA), $active-fill var(--hoverB), $active-fill var(--hoverC), $light-blue var(--hoverD), $white var(--hoverE));
  transition: --hoverA 1s, --hoverB 1s, --hoverC 1s, --hoverD 1s, --hoverE 1s;
  --hoverA: 70%;
  --hoverB: 50%;
  --hoverC: 90%;
  --hoverD: 85%;
  --hoverE: 115%;
}

.photoTargetDrop {
  background-image: -webkit-radial-gradient(circle at 50%, rgb(202, 226, 245) var(--dropA), $active-fill var(--dropB), $active-fill var(--dropC), $light-blue var(--dropD));
  background-image: -o-radial-gradient(circle at 50%, rgb(202, 226, 245) var(--dropA), $active-fill var(--dropB), $active-fill var(--dropC), $light-blue var(--dropD));
  background-image: -moz-radial-gradient(circle at 50%, rgb(202, 226, 245) var(--dropA), $active-fill var(--dropB), $active-fill var(--dropC), $light-blue var(--dropD));
  background-image: radial-gradient(circle at 50%, rgb(202, 226, 245) var(--dropA), $active-fill var(--dropB), $active-fill var(--dropC), $light-blue var(--dropD));
  transition: --dropA 1s ease-in-out, --dropB 1s ease-in-out, --dropC 1s ease-in-out, --dropD 1s ease-in-out;
  --dropA: 100%;
  --dropB: 100%;
  --dropC: 100%;
  --dropD: 100%; 
}