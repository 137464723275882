@import "../../../sass/abstracts/mixins";

.bottomNavigation {
  bottom: 0px;
  position: fixed;
  width: 100%;
  box-shadow: 0px 1px 3px rgba($black, 0.2);
  left: 0px;
  z-index: 2;
}

.icon {
  font-size: 1.5rem;
}

.activeTab {
  color: $primary !important;
  font-weight: 500;
}
