@import "../../../sass/abstracts/mixins";

.menuHeader {
  display: flex;
  margin-bottom: 34px;
  padding: 16px 12px 0px 12px;

  .initials {
    font-size: rem-calc(24);
  }
}

.col {
  display: flex;
  flex-direction: column;
}

.username {
  margin: 0;
  padding: 0;
}
