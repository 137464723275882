@import "../../sass/abstracts/mixins";

.listItem {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  border: 1px solid $light-grey;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  padding: 0.75rem;

  &.active {
    background-color: $active-fill;
    border-color: $primary;
    cursor: auto;
  }
  &.disabled {
    background-color: $light-grey;
    cursor: "not-allowed";
  }
}
