.table {
  border-collapse: collapse;
  width: 100%;

  @media print {
    page-break-inside: auto
  }

  &.fixed {
    table-layout: fixed;
  }
}
