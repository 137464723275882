@import "../../../../shared/src/sass/abstracts/functions";
@import "../../../../shared/src/sass/abstracts/variables";

.cardItemWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey-blue;
  border-radius: 8px;
  padding: 16px;
  flex-grow: 1;
}

.cardTitle {
  font-size: rem-calc(24);
  font-weight: $font-weight-medium;
  margin-bottom: 1em;
}

.section {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid $light-grey;
  padding-bottom: 4;
  margin-bottom: 6;
}
.total {
  font-weight: 500;
  font-size: 18;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.chartWrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.pieChart {
  width: 128px;
  height: 128px;
}

.percentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: rem-calc(24);
}

.percentage {
  font-size: rem-calc(36);
  font-weight: $font-weight-bold;
  color: $black;
}

.complete {
  color: $dark-grey-blue;
}

.wide {
  width: 100%;
  margin-left: 18px;
  margin-right: 18px;
}
.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.icon {
  text-decoration: none;
  font-size: 1.5em;
}
