@import "../../../../sass/abstracts/mixins";

@import "../../../../sass/abstracts/variables";
@import "../../../../sass/abstracts/functions";

.tabsContainer {
  overflow-y: scroll;
  overflow: initial;
  width: rem-calc(378);
  // full height minus header wrapper height
  display: flex;
  flex-direction: column;
  height: 100%;
  border-style: solid;
  border-width: 0 1px;
  background-color: $white;
  border-color: $medium-grey;
  color: $dark-grey;
  flex-shrink: 0;
  padding-top: 1rem;
}

.settingsTab {
  text-align: left;
  font-size: 0.75rem;
  padding: 1rem;
  position: relative;
  background: $white;
  margin-bottom: rem-calc(10);
  border-radius: 0 4px 4px 0;
}

.tabActive {
  width: calc(100% + 0.75rem);
  padding: calc(1rem - 2px) 1rem; // prevent size changes from added border
  background: $active-fill;
  border-color: $primary;
  border-width: 2px;
  border-left: 0;
  border-style: solid;

  & .tabSubLabel {
    color: $primary;
  }
}

.tabLabel {
  color: $black;
  font-family: $font-family-body;
  font-weight: $font-weight-medium;
  font-size: 16px;
}

.tabSubLabel {
  color: $dark-grey;
  text-transform: none;
  font-family: $font-family-body;
  font-weight: $font-weight-regular;
  letter-spacing: 0.22pt;
}
