@import "../../../sass/abstracts/mixins";

.button {
  @include reset-button;
  @include focus-indicator-disappear;
  @include font-medium-uppercase;
  align-items: center;
  border-radius: rem-calc(4);
  color: $dark-grey-blue;
  display: inline-flex;
}
.buttonActive {
  color: $primary;
}
.buttonDisabled {
  color: $grey-blue;
}
.buttonHovered {
  &:hover {
    color: $black;
    cursor: pointer;
  }
}
.buttonKeyboardFocused {
  &:focus {
    @include focus-indicator-appear;
    color: $black;
  }
  &:focus:active {
    color: $primary;
  }
}
.sortOrderFillActive {
  fill: $primary;
}
.sortOrderFillDisabled {
  fill: $grey-blue;
}
.sortOrderFillEnabled {
  fill: $dark-grey-blue;
}
.sortOrderFillHovered {
  fill: $black;
}
.sortOrderFillKeyboardFocused {
  fill: $black;
}
.sortOrderIcon {
  height: rem-calc(24);
  margin-left: rem-calc(4);
  width: rem-calc(24);
}
.tableHeader {
  &:first-child {
    padding-left: rem-calc(16);
  }
  padding-bottom: rem-calc(16);
  padding-right: rem-calc(16);
  padding-top: rem-calc(16);
  text-align: left;
}
