@import "../../../sass/abstracts/mixins";

.list {
  @include reset-list;
}

.option {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}
