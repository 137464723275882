@import "../../../sass/abstracts/mixins";

.readOnly {
  @media (min-width: $min-desktop) {
    max-width: 1024px;
    padding: 0 8px;
    padding-bottom: 150px;
  }

  .rehuddleInfo {
    padding: 30px 0 20px 0;
  }

  .map {
    width: 100%;
    height: 18em;
    margin-bottom: 1.875em;
  }

  .section {
    border-bottom: 1px solid $light-grey;
    padding: 1.5rem 0;

    .label {
      font-size: 1rem;
      margin-bottom: 1rem;
      font-weight: 500;
      margin-top: 0;
    }
  }

  .response {
    @include flex-row;

    .labelWrapper {
      flex-basis: 25%;
      flex-shrink: 0;
      margin: 0;

      @media (max-width: $max-phone) {
        flex-basis: 35%;
      }
    }

    .label {
      font-weight: 400;
      font-size: 0.875rem;
      margin-bottom: 0;
    }

    .value {
      color: $dark-grey;
      white-space: pre-wrap;
      hyphens: auto;
      word-break: break-word;
    }
  }
}
