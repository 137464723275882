@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

$field-font-size: rem-calc(14);

.text {
  font-size: $field-font-size;
}

.label {
  font-size: rem-calc(16);
  :nth-child(1n) button {
    font-size: rem-calc(12);
    text-decoration: underline;
  }
}

//EndAdornmentButtonClass - not sure if this is needed
.textInput :nth-child(1n) {
  text-decoration: none;
}

//EndAdornmentButtonClass - not sure if this is needed
.textInputWithSuggestions {
  text-decoration: none;
}

.checkbox {
  display: flex;
  margin: 0;
  align-items: flex-start;

  :nth-child(1n) .MuiButtonBase-root {
    padding: 0.25rem 0.25rem 0.25rem 0;
  }

  :nth-child(1n) .MuiFormControlLabel-label {
    padding-top: calc(0.25rem + 2px);
  }
}

.noVertPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.checkboxContainer {
  margin-bottom: 0;

  :nth-child(1n) .MuiFormControlLabel-label {
    margin-top: 0;
  }
}

.dropdown {
  :nth-child(n) .MuiInputBase-root {
    min-width: 4rem; // make space for the icon
  }
}

.dropdownLabelAppended {
  :nth-child(n) .MuiInputBase-root {
    order: 1;
    margin-right: rem-calc(8);
  }

  flex-direction: row;

  :nth-child(n) .fieldLabel {
    order: 2;

    :nth-child(n) label {
      font-weight: 400;
    }
  }
}

.radioButtonsGroup {
}

.multiInput {
  :nth-child(1n) .MuiListItem-root {
    font-size: $field-font-size;
  }

  :nth-child(1n) ul {
    padding: 0;
  }

  :nth-child(1n) .listItem {
    padding: 0.25rem 0.25rem 0;
    margin-bottom: 0;

    :nth-child(1n) .MuiListItem-root {
      margin-bottom: 0;
    }

    :nth-child(1n) .MuiTypography-body1 {
      font-size: $field-font-size;
    }
  }
}

.hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: rem-calc(16) 0 rem-calc(16) 0;
}

.properties {
  padding: 0 1rem 1rem;
  overflow-y: auto;
  flex: 1;

  :nth-child(1n) hr {
    width: 100%;
  }

  // global properties field styles
  :nth-child(1n) .MuiFormControlLabel-label {}

  :nth-child(1n) .fieldLabel {
    font-size: $field-font-size;
    color: $dark-grey;
  }

  :nth-child(1n) .input {}

  :nth-child(1n) .MuiInputBase-input {}

  :nth-child(1n) .TextInput {
    padding: 0.5rem;
    font-size: $field-font-size;
    height: auto;
  }

  :nth-child(1n) .MuiTextField-root {}

  :nth-child(1n) .select {}

  :nth-child(1n) .multiInput {
    .input {}

    .MuiInputBase-input {
      padding: 0.5rem;
      font-size: $field-font-size;
      height: auto;
    }
  }

  :nth-child(1n) .MuiInputLabel-root {
    font-size: $field-font-size;
    margin-bottom: 0.05rem;
    margin-top: 0.75rem;
  }

  :nth-child(1n) .selectInput {
    margin-left: 1rem;
  }

  :nth-child(1n) .select {
    height: 2.5rem;
  }
}

.requiredForContainer {
  margin-left: 1.8rem;
}

.requiredForLabel {
  font-size: $field-font-size;
  color: $dark-grey;
  margin-bottom: 0.5rem;
}

.richTextEditor {
  :nth-child(1n) #wysiwyg-editor {
    font-size: $field-font-size;
    padding: 0.5rem;
    color: $black;
  }

  :nth-child(1n) .wysiwyg-style-buttons {
    display: flex;
    padding: 0.25rem 0.5rem;
    justify-content: space-between;
  }

  :nth-child(1n) .wysiwyg-style-button {
    font-size: 1rem;
    width: auto;
    flex-basis: 10%;
  }
}

.participantDs {
  margin: 0.5rem 0;
  padding-left: 0.5rem;

  :nth-child(1n) .MuiCheckbox-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  :nth-child(1n) .MuiFormControlLabel-label {}

  :nth-child(1n) .fieldLabel {
    margin-top: 0;
  }

  :nth-child(1n) .MuiFormControlLabel-root {
    margin-bottom: 0;
  }
}

.textButton {
  padding: 0;
  margin-bottom: 0;
}

.addIcon {
  font-size: rem-calc(24);
}

.dcRow {
  padding-bottom: 0.5rem;
  text-align: right;
}

.dcRule {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $medium-grey;
  margin: 1rem 0;
}

.ruleTypeSelect {
  margin-right: 0.5rem;
}

.dcSelect {
  flex-grow: 1;
  margin-left: 0.5rem;
  min-width: 90px;
}

.actionSelect {
  width: rem-calc(72);
}

.prefillSelect {
  width: rem-calc(196);
}

.showHideSelect {
  width: rem-calc(148);
}

.conditionRules {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.link {
  text-decoration: underline;
  font-size: rem-calc(14);
  letter-spacing: 0.15px;
}

.newRuleButton {
  color: $black;
  text-transform: none;
  letter-spacing: normal;
  width: 100%;
  padding: 0;
  margin: 0;
}

.newRuleText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.workLocationNotice {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: rem-calc(14);
  font-style: italic;
}

.addOptionButton {
  min-height: 36px;
  padding: 10px 20px;
}

.customAnswerButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.commentRequired {
  margin-top: 18px;
}

.drawerContent {
  padding: 4px 16px 16px 16px;
}

.drawerSection {
  margin-bottom: 28px;
}

.help {
  font-size: 0.75rem;
  line-height: 1.16667;
}
