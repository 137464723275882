@import "../../../../sass/abstracts/mixins";

.scaleContainer {
  background: $light-white;
  padding: 0.5rem 1rem;
}
.headerContainer {
  margin-bottom: 1rem;
}
.header {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.scaleRow {
  margin: 0.75rem 0.5rem;
}
