@import "../../../../sass/abstracts/mixins";

.signatureWrapper {
  border: 1px solid $dark-grey;
  border-radius: 4px;
  height: 56px;

  &.drawn {
    padding: 16px;

    .signatureImg {
      object-fit: contain;
      height: 100%;
      width: auto;
    }
  }
}
