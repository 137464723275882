@import "shared/src/sass/abstracts/variables";

.block {
  flex-direction: row;
}

.blockCol {
  flex-direction: column;
}
  
.text {
  font-size: 10;
}
 
.subText {
  color: $dark-grey;
  margin-top: 2px;
}