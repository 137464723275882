@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";

.formField {
  cursor: pointer;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;

  .label {
    align-self: center;
  }

  .errorLabel {
    align-self: center;
    color: $error;
  }

  .disabledLabel {
    align-self: center;
    color: $dark-grey-blue;
    cursor: not-allowed;
  }

  svg {
    display: block;
    fill: transparent;

    .fill {
      fill: $dark-grey-blue;
    }
  }

  .checkmark {
    opacity: 1;
    margin-right: rem-calc(10);
    min-width: rem-calc(24);
  }

  input {
    display: none;
    &[type="checkbox"]:checked {
      + .checkmark {
        opacity: 1;
      }

      + svg .fill {
        fill: $primary;
      }
    }

    &[type="checkbox"]:disabled {
      + .checkmark {
        opacity: 1;
        cursor: not-allowed;
      }

      + svg .fill {
        fill: $dark-grey-blue;
      }

      + svg .background {
        fill: $medium-grey;
      }
    }

    &[type="checkbox"] {
      + .checkmark {
        opacity: 1;
      }

      + svg .errorFill {
        fill: $dark-grey-blue;
      }

      + svg .errorBackground {
        fill: $error-fill;
      }
    }

    &[type="checkbox"]:checked {
      + .checkmark {
        opacity: 1;
      }

      + svg .errorFill {
        fill: $primary;
      }

      + svg .errorBackground {
        fill: $error-fill;
      }
    }

    &[type="checkbox"]:checked:disabled {
      + .checkmark {
        opacity: 1;
      }

      + svg .fill {
        fill: $primary;
      }

      + svg .background {
        fill: $medium-grey;
      }
    }

    &:hover {
      + svg .background {
        fill: $light-blue;
      }
    }
  }
}
