@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
.calendar {
  border-collapse: collapse;
}
.columnHeader {
  @include small-regular;
  border: rem-calc(2) solid transparent;
  color: $dark-grey;
  height: rem-calc(28);
  width: rem-calc(28);
}
