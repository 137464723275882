@import "../../../../../../shared/src/sass/abstracts/mixins";
@import "../../../../../../shared/src/sass/abstracts/variables";

.assessmentContainer {
  text-align: left;
  display: flex;
  width: 100%;
  padding-bottom: 18px;
  flex-direction: row;
  justify-content: space-between;
}

.assessmentHeader {
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2px 0;
}

.iconContainer {
  height: fit-content;
  cursor: pointer;
}

.icon {
  font-size: 1.5rem;
}

.flexContainer {
  display: flex;
  flex-direction: column;
}

.substationName {
  color: $black;
}

.statusContainer {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  color: $dark-grey;
  
  .statusLabelIcon {
      padding-top: 1px;
  }

  .statusLabelText {
    padding-left: rem-calc(5);

    > span {
      padding-right: rem-calc(5);
    }
  }
}

