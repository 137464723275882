@import "../../../../sass/abstracts/mixins";

@import "../../../../sass/abstracts/variables";
@import "../../../../sass/abstracts/functions";

.loader {
  margin-bottom: 16px;
  margin-top: 16px;
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  min-width: rem-calc(378);
  width: rem-calc(378);
  border-style: solid;
  border-width: 0 1px;
  background-color: $white;
  border-color: $medium-grey;  
  padding-top: 1rem;
}

$padding: calc(1rem - 2px) 1rem; // prevent size changes from added border
.title {
  @include font-medium-uppercase;
  color: $black;
  font-size: 0.75rem;
  letter-spacing: 1.88pt;
  padding-left: 1rem;
}

.scrollView {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: scroll;
}

.tabLabel {
  color: $dark-grey;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  letter-spacing: 0.5pt;
}

.tabSubLabel {
  color: $dark-grey-blue;
  font-size: 1rem;
  font-weight: $font-weight-regular;
  letter-spacing: 0.5pt;
}

.labelBox {
  border-bottom: 1px solid $dark-grey-blue;
  padding: 1rem 0;
  line-height: 1.2rem;
}

.tab {
  font-size: 0.75rem;
  font-weight: $font-weight-medium;
  cursor: pointer;
  line-height: 1.1rem;
  text-align: left;
  padding: 0 1rem;
  position: relative;
  background: $white;
  border-radius: 0 4px 4px 0;
  border: 2px solid transparent;
  border-left: 0;

  &Published {
    background-color: #F9F9F9;

    &.tabActive {
      width: calc(100% + 0.75rem);
    }
  }

  &Active {
    border: 2px solid $primary;
    border-left: 0;
    color: $primary;
    background: $active-fill;  
    & .tabLabel {
      color: $primary
    }
    & .tabSubLabel {
      color: $primary;
    }
    & .labelBox {
      border-color: transparent;
    }
  }

  &BeforeActive {
    & .labelBox {
      border-color: transparent;
    }
  }
}

.publishedHeader {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.starBanner {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  width: 28px;
  height: 36px;
  margin-right: 6px;
  & i {
    color: $white;
    transform: translateY(-2px);
  }
  &:after {
    // https://stackoverflow.com/a/28013173
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 6px solid $active-fill;
    -moz-transform: scale(0.999);
    -webkit-backface-visibility: hidden;
  }
}
.publishedLabel {
  color: $black;
  text-transform: uppercase;
  letter-spacing: 1.4pt;
}

.tabActionContainer {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabActionBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.tabActionIcon {
  color: $white;
  font-size: 1.2rem;
}

.tabAction {
  display: block;
  text-transform: uppercase;
  letter-spacing: 1.4pt;
}