@charset "UTF-8";

@import "../../../sass/abstracts/functions";
@import "../../../sass/abstracts/variables";

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;

  .cancelButton {
    margin-right: 10px;
  }
}