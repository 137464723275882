@import "../../../sass/abstracts/mixins";

.userMenu {
  position: relative;
  color: $medium-grey;
  font-size: rem-calc(14);
  font-weight: 700;
}

.initials {
  border-radius: 50%;
  width: rem-calc(50);
  height: rem-calc(50);
  border: 1px solid $light-grey;
  padding: 9px;
  margin-right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-grey;

  font-size: 16px;
  font-weight: 600;

  /*   
    This element is used in 2 locations which each require
    a different font-size. We'll use this boolean prop
    to determine the font-size
  */
  // ${({ inMenu }) => {
  //   if (inMenu) {
  //     return `
  //       font-size: 24px;
  //     `;
  //   }
  // }}
}

.label {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.signOutText {
  color: $error;
  padding: 0;
  margin: 0;
  font-size: rem-calc(14);
}

.expandIcon {
  color: $dark-grey;
}

.button {
  outline: 0;

  &:focus {
    outline: 0;
    background-color: $white;
  }
}

.menuItem {
  min-width: 220px;
}
