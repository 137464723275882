@import "shared/src/sass/abstracts/variables";

.emptyRow {
  text-align: center;
  padding: 80px 0;
}

.noResultsContentContainer {
  max-width: 390px;
  margin: 0 auto;
}

.centered {
  text-align: center;
}

.noResultsTitle {
  font-size: 1.5rem;
  line-height: 21px;
  margin-top: 1.5rem;
}

.noResultsBody {
  color: $dark-grey;
  margin: 0.75rem 0;
}
