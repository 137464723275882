@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";

.breadcrumbWrapper {
  border-bottom: 1px solid $light-grey;
  padding-bottom: 13px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  @media print {
    display: none;
  }
}

.crumb {
  color: $dark-grey;
  font-size: rem-calc(12);
  font-weight: $font-weight-bold;
}
.crumbCurrent {
  color: rgba($black, 0.87);
  font-size: rem-calc(12);
  font-weight: $font-weight-bold;
}

.link {
  color: $dark-grey;
  cursor: pointer;
  display: block;
  font-size: rem-calc(12);
  font-weight: $font-weight-medium;
}
