@import "../../sass/abstracts/_variables.scss";

.contentWrapper {
  max-width: 990px; // 30px added to offset inside padding
  // min-width: 790px; // 30px added to offset inside padding
  padding: 0 15px;
  height: 100%;
  width: 100%;

  @media print {

  }
}

// @media print {
//   .contentWrapper {
//     break-before: always;
//   }
// }

.responsiveContentWrapper {
  max-width: 990px;
  padding: 0 15px;

  @media (max-width: $max-tablet) {
    padding: 0 15px 100px 15px; // For bottom navigation
  }
}
