@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?bx9m19');
  src:  url('fonts/icomoon.eot?bx9m19#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?bx9m19') format('truetype'),
    url('fonts/icomoon.woff?bx9m19') format('woff'),
    url('fonts/icomoon.svg?bx9m19#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icons8-action-required:before {
  content: "\e96b";
}
.icon-icons8-assign:before {
  content: "\e96c";
}
.icon-icons8-history:before {
  content: "\e96d";
}
.icon-icons8-photos:before {
  content: "\e96e";
}
.icon-icons8-review:before {
  content: "\e96f";
}
.icon-icons8-verify:before {
  content: "\e971";
}
.icon-icons8-reset:before {
  content: "\e970";
}
.icon-storm_marker:before {
  content: "\e969";
}
.icon-nothing_found:before {
  content: "\e968";
}
.icon-icons8-export_pdf:before {
  content: "\e965";
}
.icon-icons8-high_priority:before {
  content: "\e96a";
}
.icon-icons8-error-filled:before {
  content: "\e964";
}
.icon-icons8-minus_sign:before {
  content: "\e961";
}
.icon-icons8-content:before {
  content: "\e960";
}
.icon-icons8-add_user_male:before {
  content: "\e927";
}
.icon-icons8-add:before {
  content: "\e928";
}
.icon-icons8-clapperboard:before {
  content: "\e949";
}
.icon-icons8-defense:before {
  content: "\e94a";
}
.icon-icons8-development_skill:before {
  content: "\e94b";
}
.icon-icons8-drag_reorder:before {
  content: "\e94c";
}
.icon-icons8-dropdown_field:before {
  content: "\e94d";
}
.icon-icons8-edit_row:before {
  content: "\e94e";
}
.icon-icons8-globe:before {
  content: "\e94f";
}
.icon-icons8-help1:before {
  content: "\e950";
}
.icon-icons8-horizontal_line:before {
  content: "\e951";
}
.icon-icons8-ipad:before {
  content: "\e952";
}
.icon-icons8-iphone:before {
  content: "\e953";
}
.icon-icons8-laptop:before {
  content: "\e954";
}
.icon-icons8-lock1:before {
  content: "\e955";
}
.icon-icons8-map:before {
  content: "\e956";
}
.icon-icons8-paragraph:before {
  content: "\e957";
}
.icon-icons8-pencil1:before {
  content: "\e958";
}
.icon-icons8-radio_button:before {
  content: "\e959";
}
.icon-icons8-search1:before {
  content: "\e95a";
}
.icon-icons8-shared_document:before {
  content: "\e95b";
}
.icon-icons8-sort_up:before {
  content: "\e95c";
}
.icon-icons8-tags:before {
  content: "\e95d";
}
.icon-icons8-todo_list:before {
  content: "\e95e";
}
.icon-icons8-true_false:before {
  content: "\e95f";
}
.icon-icons8-align_justify:before {
  content: "\e945";
}
.icon-icons8-table:before {
  content: "\e946";
}
.icon-icons8-delete_link:before {
  content: "\e944";
}
.icon-icons8-indent:before {
  content: "\e93f";
}
.icon-icons8-link:before {
  content: "\e940";
}
.icon-icons8-list:before {
  content: "\e941";
}
.icon-icons8-numbered_list:before {
  content: "\e942";
}
.icon-icons8-outdent:before {
  content: "\e943";
}
.icon-icons8-pdf:before {
  content: "\e93b";
}
.icon-icons8-messaging:before {
  content: "\e939";
}
.icon-icons8-send_comment:before {
  content: "\e937";
}
.icon-icons8-pencil:before {
  content: "\e933";
}
.icon-icons8-flag:before {
  content: "\e932";
  color: #c3c3c3;
}
.icon-icons8-lock:before {
  content: "\e938";
}
.icon-icons8-star:before {
  content: "\e935";
}
.icon-icons8-star-filled:before {
  content: "\e936";
}
.icon-icons8-no-synchronize:before {
  content: "\e930";
  color: #666;
}
.icon-icons8-synchronize:before {
  content: "\e931";
  color: #666;
}
.icon-icons8-phonelink_setup:before {
  content: "\e934";
  color: #666;
}
.icon-icons8-cancel:before {
  content: "\e92e";
  color: #666;
}
.icon-icons8-checked:before {
  content: "\e92f";
  color: #666;
}
.icon-icons8-user_male_circle:before {
  content: "\e92d";
}
.icon-icons8-error:before {
  content: "\e92c";
}
.icon-icons8-comments:before {
  content: "\e926";
  color: #0172ce;
}
.icon-name_picker:before {
  content: "\e91a";
}
.icon-more_vert:before {
  content: "\e967";
}
.icon-icons8-help:before {
  content: "\e93a";
}
.icon-cheveron-up:before {
  content: "\e99e";
}
.icon-cheveron-down:before {
  content: "\e9a0";
}
.icon-cheveron-up1:before {
  content: "\e99f";
}
.icon-cheveron-down1:before {
  content: "\e9a1";
}
.icon-icons8-clock:before {
  content: "\e966";
}
.icon-share:before {
  content: "\e962";
}
.icon-icons8-pencil_fill:before {
  content: "\e93d";
  color: #666;
}
.icon-icons8-phone_settings:before {
  content: "\e93c";
  color: #666;
}
.icon-icons8-info:before {
  content: "\e929";
}
.icon-icons8-delete_trash:before {
  content: "\e92a";
  color: #fff;
}
.icon-icons8-email:before {
  content: "\e92b";
  color: #fff;
}
.icon-icons8-documents:before {
  content: "\e93e";
  color: #fff;
}
.icon-icons8-calendar:before {
  content: "\e947";
}
.icon-icons8-export_csv:before {
  content: "\e91e";
}
.icon-icons8-multi_edit:before {
  content: "\e91f";
}
.icon-icons8-urgent_message:before {
  content: "\e920";
}
.icon-icons8-business_conference_female_speaker:before {
  content: "\e921";
}
.icon-icons8-xls_export:before {
  content: "\e922";
}
.icon-icons8-a_home:before {
  content: "\e923";
}
.icon-icons8-print:before {
  content: "\e924";
}
.icon-icons8-overview_pages_4:before {
  content: "\e925";
}
.icon-icons8-linked_file:before {
  content: "\e91d";
}
.icon-icons8-document-2:before {
  content: "\e91c";
}
.icon-icons8-person_male:before {
  content: "\e91b";
}
.icon-icons8-chevron-left:before {
  content: "\e919";
}
.icon-icons8-chevron-right:before {
  content: "\e948";
}
.icon-icons8-add_file:before {
  content: "\e918";
}
.icon-icons8-administrator_male:before {
  content: "\e900";
}
.icon-icons8-autograph:before {
  content: "\e901";
}
.icon-icons8-checkmark:before {
  content: "\e902";
}
.icon-icons8-combo_chart:before {
  content: "\e903";
}
.icon-icons8-data_configuration:before {
  content: "\e904";
}
.icon-icons8-delete_sign:before {
  content: "\e906";
}
.icon-icons8-document:before {
  content: "\e907";
}
.icon-icons8-expand_arrow:before {
  content: "\e908";
}
.icon-icons8-extra_features:before {
  content: "\e909";
}
.icon-icons8-lightning_bolt:before {
  content: "\e90a";
}
.icon-icons8-mail_filter:before {
  content: "\e90b";
}
.icon-icons8-marker:before {
  content: "\e963";
}
.icon-icons8-menu:before {
  content: "\e90c";
}
.icon-icons8-microphone:before {
  content: "\e90d";
}
.icon-icons8-minus2:before {
  content: "\e90e";
}
.icon-icons8-moon_symbol:before {
  content: "\e90f";
}
.icon-icons8-more:before {
  content: "\e910";
}
.icon-icons8-place_marker:before {
  content: "\e911";
}
.icon-icons8-plus_math:before {
  content: "\e912";
}
.icon-icons8-search:before {
  content: "\e913";
}
.icon-icons8-sort_down:before {
  content: "\e914";
}
.icon-icons8-sun:before {
  content: "\e915";
}
.icon-icons8-under_construction:before {
  content: "\e916";
}
.icon-icons8-user_female_circle:before {
  content: "\e917";
}
.icon-icons8-plus_math1:before {
  content: "\e972";
}
.icon-camera:before {
  content: "\e99d";
}
.icon-photo-icons:before {
  content: "\e9a2";
}
