@import "../../../../shared/src/sass/abstracts/variables";
@import "./common";

.errorWrapper {
  @include wrapper;
  flex-direction: row;
  background: $error-fill;
  border: 1px solid $error;
}

.errorProgressWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.errorProgressBar {
  color: $error;
}

.errorProgressText {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $error;
}

.progressWrapper {
  @include wrapper;
  flex-direction: row;
  background: $white;
  border: 1px solid $primary;
}

.progressArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 12px;
}

.progressTextLine {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
