/* Roboto regular */
@font-face {
  font-family: "Roboto";
  src: url("/shared/src/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Roboto regular italic */
@font-face {
  font-family: "Roboto";
  src: url("/shared/src/assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Roboto medium */
@font-face {
  font-family: "Roboto";
  src: url("/shared/src/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Roboto medium italic */
@font-face {
  font-family: "Roboto";
  src: url("/shared/src/assets/fonts/Roboto-MediumItalic.ttf")
    format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Roboto bold */
@font-face {
  font-family: "Roboto";
  src: url("/shared/src/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Roboto bold italic */
@font-face {
  font-family: "Roboto";
  src: url("/shared/src/assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
