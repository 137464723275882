@import "../../../../sass/abstracts/mixins";

.startNewFormBtn {
  position: absolute;
  top: -30px;
  z-index: 10000;

  border-radius: 50%;
  width: 56px;
  height: 56px;

  background-color: $primary;
  color: $white;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 26px;
}

.styledIcon {
  font-size: 1.5rem;
}
