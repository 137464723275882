@import "../../../../shared/src/sass/abstracts/variables";

.title {
  align-self: center;
  color: $white;
  font-family: "Roboto";
  font-size: 2rem;
  margin-bottom: 40px;
  font-weight: 700;
}

.center {
  display: flex;
  justify-content: center;
}

.description {
  width: 75vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 0;
  color: $white;
  font-family: "Roboto";
  font-size: 20px;
}