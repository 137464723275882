@import "../../../../sass/abstracts/mixins";

.commentContainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.removeCommentLink {
  color: $error;
  align-self: flex-end;
  margin-top: 0.5rem;
}

.required {
  color: $error;
  margin-top: 0.375rem;
  display: block;
  align-self: flex-start;
}

.inputWrapper {
  padding: 0.25rem 0.825rem;
}
