@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/functions";

.header {
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: rem-calc(15) rem-calc(16);
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  box-shadow: 0px 2px 4px -1px RGB(0 0 0 / 20%),
    0px 4px 5px 0px RGB(0 0 0 / 14%), 0px 1px 10px 0px RGB(0 0 0 / 12%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.title {
  display: inline-block;
  font-size: rem-calc(20);
  font-weight: 500;
  margin: 0px 50px 0px 0px;
  text-align: center;
  padding: 0px;
  color: $black;
}

.backButton {
  width: 56px;
  height: 100%;
  border: none;
  background: none;
  margin: 0;
  padding-top: 5px;
  outline: 0;
  text-transform: none;
  overflow: visible;
  font-size: 1.5rem;
}

.placeholder {
  width: 5px;
  user-select: none;
  height: 5px;
}
