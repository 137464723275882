@import "../../../sass/abstracts/mixins";

.wrapper {
  padding-top: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  font-style: italic;
  margin-right: 10px;
}

.select {
  padding: 4px 24px 4px 6px !important;
  font-size: rem-calc(14) !important;
  min-width: 6.25rem;

  :global(.react-select__single-value) {
    font-weight: 500;
  }
  :global(.react-select__value-container) {
    padding: 0.25rem;
  }
  :global(.react-select__indicator-separator) {
    display: none;
  }
}
