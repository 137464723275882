@import "../../../../../shared/src/sass/abstracts/mixins";

.appBar {
  position: fixed;
  background: $background;
  box-shadow: none;
  width: 100%;
  z-index: 100;

  .title {
    font-size: rem-calc(20);
    font-weight: 500;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }

  .icon {
    color: $dark-grey;
  }

  .actionsContainer {
    display: flex;

    .additionalActionsContainer {
        margin-right: 10px;
    }
  }
}
