@import "shared/src/sass/abstracts/mixins";
@import "shared/src/sass/abstracts/variables";

$tab-width: 30px;

.backdrop {
  background-color: $white;
  border: 1px solid $medium-grey;
  border-radius: 4px;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 650px;
  min-height: 200px;
  padding: 30px;
}

.historyPreview {
  align-items: flex-start;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-right: -$tab-width;
}

.previewTab {
  background-color: $warning-fill;
  border: 1px solid $warning;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: $warning;
  display: block;
  font-weight: 500;
  font-size: rem-calc(12);
  letter-spacing: 1.88px;
  line-height: 1.16667;
  margin: 27px 0 0 -1px;
  padding: 13px 7px;
  position: sticky;
  text-transform: uppercase;
  top: 0;
  width: $tab-width;
  writing-mode: vertical-lr;
}
