@import "../../../../shared/src/sass/abstracts/mixins";
@import "../RadioButtons/RadioButton.module.scss";

.radioGroup {
  display: flex;
}

// inherits RadioButton.module.scss
.rating {
  display: flex;
  flex-grow: 1;
  text-align: center;
  flex-basis: 25%;

  .label {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    background-color: inherit;
    padding: 1rem rem-calc(12);
    border-color: $dark-grey;
    border-style: solid;
    border-radius: 0;
    border-width: 1px 0 1px 1px;
    box-shadow: 0 0 0 rem-calc(5) transparent,
      inset 0 0 0 rem-calc(1) transparent;

    &.checked {
      background-color: $active-fill;
    }

    &:hover {
      background-color: $active-fill;
    }

    .text {
      font-size: rem-calc(20);
      line-height: rem-calc(28);
      padding: 0;
      padding-top: rem-calc(8);
    }
  }

  &:first-of-type {
    .label {
      border-bottom-left-radius: 1rem;
      border-top-left-radius: 1rem;
    }
  }

  &:last-of-type {
    .label {
      border-bottom-right-radius: 1rem;
      border-right-width: 1px;
      border-top-right-radius: 1rem;
    }
  }

  &:focus-within {
    .label {
      border-color: $focus-indicator-outline;
      box-shadow: 0 0 0 rem-calc(3) $focus-indicator-outline,
        inset 0 0 0 1px $focus-indicator-outline;
      position: relative;
    }
  }
}
