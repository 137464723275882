@import "../../../../shared/src/sass/abstracts/functions";
@import "../../../../shared/src/sass/abstracts/variables";

.substationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.substationRowWrapper {
  display: flex;
  flex-direction: column;
}

.substationNameWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.substationName {
  color: $black;
}

.substationDistance {
  color: $dark-grey;
}
