@import "../../../../shared/src/sass/abstracts/functions";
@import "../../../../shared/src/sass/abstracts/variables";

.labelContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.label {
  color: $dark-grey;
  margin-left: 8px;
}
