@import "../../../../../sass/abstracts/mixins";

.editButton {
  @include reset-button;

  color: $primary;
  cursor: pointer;
  display: inline;
  font-size: 1rem;

  & i {
    vertical-align: middle;
  }
}
