@import "../../sass/abstracts/mixins";

.toast {
  top: 0;
  left: 0;
  display: flex;
  margin-top: rem-calc(75);
  z-index: 999;
  font-size: rem-calc(14);
  position: relative;
  z-index: 9;
}

.actionButton {
  margin: 0.5rem;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
}

.icon {
  font-size: 2rem;
  margin-right: 1rem;
}
