@import "shared/src/sass/abstracts/mixins";

.desktopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $background;
  box-shadow: $light-grey 0px 1px 3px;
  margin-bottom: 8px;
  padding-top: rem-calc(1);
  padding-bottom: rem-calc(1);
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 11;
}

.mobileHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: rem-calc(16);
  background: $background;
}

.appName {
  font-weight: 700;
  font-size: 20px;
}