@import "../../../src/sass/abstracts/mixins";

@mixin innerCircle {
  border-radius: 50%;
  content: "";
  display: block;
  height: 1rem;
  transform: scale(0);
  transition: transform 0.15s;
  width: 1rem;
}

@mixin innerCircleChecked {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "icomoon";
  content: "\e92f";
  color: $primary;
  transform: scale(1);
  transition: transform 0.15s;
  width: 100%;
  height: 100%;
  font-size: 1.85rem;
}

@mixin outerCircle {
  border-color: $dark-grey-blue;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
}

.input {
  @include reset-input;
  @include outerCircle;
  height: 2rem;
  width: 2rem;

  &:after {
    @include innerCircle;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $light-blue;
  }
  &:checked {
    border-color: $primary;
    &:after {
      @include innerCircleChecked;
    }
  }
  &:disabled {
    background-color: $grey-blue;
    border-color: $dark-grey-blue;
  }
  &.error {
    border-color: $error;
    &:checked {
      &:after {
        color: $error;
      }
    }
  }
}

.label {
  @include focus-indicator-appear;
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 0.5rem;

  .text {
    padding: 0 rem-calc(24) 0 rem-calc(8);

    &.disabled {
      color: $dark-grey-blue;
    }

    &.error {
      color: $error;
    }
  }
}

.pill {
  .label {
    border: 1px solid $medium-grey;
    border-radius: 2rem;
    padding: 2px 4px;

    &.checked {
      background-color: $light-blue;
    }
  }
}
