@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.title {
  color: $black;
  font-size: rem-calc(20);
  font-weight: 500;
}

.text {
  font-style: italic;
  margin-bottom: 0;
}
