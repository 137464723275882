@import "../../../../../../shared/src/sass/abstracts/variables";
@import "../../../../../../shared/src/sass/abstracts/functions";

.h1 {
  font-weight: $font-weight-bold;
  font-size: rem-calc(36);
  margin: 0;
}

.sectionDescription {
  letter-spacing: rem-calc(2);
  color: $dark-grey;
  text-transform: uppercase;
}

.header {
  display: flex;
  padding: rem-calc(10) 0;
  margin-top: rem-calc(10);
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
