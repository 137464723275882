@import "../../sass/abstracts/variables";

.linkCaret {
  color: $dark-grey;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  transform: rotate(-90deg);
  margin-right: 10px;
  margin-left: 10px;
}