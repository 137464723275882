@import "../../../../shared/src/sass/abstracts/mixins";

.assessmentsList {
  margin-top: 1rem;
}

.assessmentsMap {
  position: relative;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  z-index: 1000;
}

.searchBarContainer {
  margin-bottom: 1rem;

  @media print {
    display: none;
  }

  .searchBar {
    margin-top: 0.5rem;
    border: 1px solid $medium-grey !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    height: auto;
    border-radius: 1.5rem !important;
  }
}

.noAssessments {
  text-align: center;
  color: $black;
  padding: 2rem 1rem;

  .label {
    @include font-medium-uppercase;
    color: $dark-grey-blue;
  }

  .noAssessmentsIcon {
    font-size: 3rem;
    color: $grey-blue;
    position: relative;
  }
}

.buttonsContainer {
    background-color: red;
    display: none;
}