@import "../../../shared/src/sass/abstracts/variables";

.tabLabel {
  display: block;
  text-align: left;
}

.tab {
  position: relative;
  text-transform: uppercase;
  max-height: none;
}

.tabSelect {
  align-self: center;

  :global(.react-select__single-value) {
    color: $primary;
    font-weight: 500;
  }
}
