@import "shared/src/sass/abstracts/mixins";

.wrapper {
  margin-bottom: rem-calc(21);
}

.map {
  margin-top: 25px;
  display: flex;
  height: rem-calc(600);
  width: 100%;

  &.fullscreen {
    margin: 0;
    width:100%;
    height: calc(100% - 65px);
    position: absolute;
    top: 65px;
    left: 0;    
  }
}

.mapPinInfo {
  margin-bottom: -10px;
  background-color: white;
  width: 400px;
  padding: 15px 0;
  border-radius: 4px;
  box-shadow: 1px 2px 2px rgba($primary, 0.2);
  cursor: default;

  .substationInfo {
    padding: 0 15px;
  }

  .actionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;    
    flex-wrap: wrap;
    padding: 0 15px;
    border-top: 1px solid $light-grey;

    .action {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      width: 60px;
      color: $primary;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: $secondary;
      }

      .actionIcon {
        display: flex;
        background-color: $light-grey;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }

      .actionText {
        display: flex;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 5px;
      }
    }
  }
}

.buttonsContainer {
    display: flex;
    height: rem-calc(60);
    border-radius: 4px;
   
    .button {
      background-color: $white;
      color: $primary;
      margin-right: 10px;
      box-shadow: 1px 2px 2px rgba($primary, 0.2);

      &.active {
        background-color: $primary;
        color: $white;
      }

      &.mapViewCount {
        cursor: default;
        padding: 0 0 20px 20px;
        position: relative;
        z-index: 1000001;
      }
    }

    .legendPane {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 70px;
      background-color: white;
      width: 250px;
      height: 370px;
      padding: 0 15px;
      border-radius: 4px;
      box-shadow: 1px 2px 2px rgba($primary, 0.2);
    }
}