@import "../../sass/abstracts/mixins";

.alert {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
}

.warning {
  background-color: $warning-fill;
  border: 1px solid $warning;
}

.error {
  background-color: $error-fill;
  border: 1px solid $error;
}

.title {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0;
}

.message {
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: rem-calc(24);
}

.title,
.message {
  @include font-size(16);
}
