@import "../../../../../shared/src/sass/abstracts/mixins";

.progressBar {
  background: $active-fill;
  height: 4px;
  overflow: hidden;
  position: relative;

  .progressIndicator {
    transition: transform 0.4s linear;
    background: $primary;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transform-origin: left;
  }
}
