@import "../../sass/abstracts/variables";

.arrow {
  color: $dark-grey;
}

.lightTooltip {
  background-color: $white;
  border: 1px solid $light-grey;
  box-shadow: 0px 1px 3px rgba($black, 0.2);
  color: $black;
  position: relative;
  max-width: none;
}

.tooltip {
  background-color: $dark-grey !important;
  border-color: $dark-grey;
  color: $white !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  line-height: 1.5;
}
