@import "../../sass/abstracts/variables";

.column {
  flex-direction: column;

  .item {
    margin-bottom: 0.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.row {
  flex-direction: row;

  .item {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
}

.error {
  border-color: $error;
  border: 1px solid $error;
  background-color: $error-fill;
}

.errorText {
  display: block;
  color: $error;
  font-size: rem-calc(16);
  margin: rem-calc(2) rem-calc(5) 0 1rem;
}

.container {
  display: flex;
  justify-content: space-between;
}
