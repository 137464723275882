@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.formBuilderHeader {
  flex: 0;
}

.publishButton {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.statusContainer {
  justify-content: flex-end;
  display: flex;
  align-items: center;

  // Select
:nth-child(1n) .MuiOutlinedInput-root {
  width: 8rem;
  margin-left: 0.75rem;
}
}

.status {
  margin-right: 0.75rem;
}

// Header
.header {
  color: $dark-grey;
  justify-content: space-between;
  font-size: 1rem;
  padding: 1rem 1.5rem;
}

.headerLink {
  color: $dark-grey;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
}

.headerTitle {
  font-size: 1.5rem;
  color: $black;
  font-weight: 400;
  margin-right: 0.5rem;
}

.headerLeft {
}

.headerRight {
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.headerCenter {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.headerSavedAt {
  display: flex;
  align-items: center;
}

.saveButton {
  padding: 0;
  margin: 0 0 0 1rem;
}

.discardDraftButton {
  padding: 0;
  margin: 0 0 0 1rem;
  color: $error;
}
