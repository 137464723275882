@import "../../../../sass/abstracts/variables";
@import "../../../../sass/abstracts/functions";
@import "../../../../sass/abstracts/mixins";

.container {
  display: flex;

  &WithoutPhoto {
    margin: 12px 0;
  }
}

.checkbox {
  display: flex;
  flex-grow: 1;
}
