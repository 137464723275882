@import "../../../../shared/src/sass/abstracts/mixins";

.summaryCardContainer {
  padding-right: 1rem;
  margin-bottom: 1rem;
  flex-basis: 25%;
  display: flex;

  &:last-of-type {
    padding-right: 0;
  }
}

.summaryCard {
  flex-grow: 1;
  border: 1px solid $grey-blue;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.status {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  color: $dark-grey;
  margin-bottom: 8px;
}

.count {
  font-size: rem-calc(36);
  font-weight: $font-weight-bold;
  margin-top: auto;
  margin-right: 8px;
}
