@import "shared/src/sass/abstracts/variables";

.button {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border: 1px solid;
  border-radius: 0.25rem;
  height: 51px;
  align-self: flex-end;
  width: 5rem;
  margin-left: 9px;

  &:hover {
    cursor: pointer;
  }
}

.buttonDisabled {
  &:hover {
    cursor: default;
  }
}

.camera {
  align-self: center;
  font-size: 1.25rem;
  padding-left: 5px;
}

.accessory {
  align-self: center;
  font-weight: bold;
  padding-left: 5px;
}

.arrow {
  border-bottom: 8px solid rgba($primary, 1);
  border-left: 8px solid transparent;
  border-right: 8px solid rgba($primary, 1);
  border-top: 8px solid transparent;
  height: 15px;
  width: 15px;
  position: relative;
  top: 40px;
  right: 50px;

  &::after {
    content: "";
    border-bottom: 8px solid $white;
    border-left: 8px solid transparent;
    border-right: 8px solid $white;
    border-top: 8px solid transparent;
    height: 0;
    left: -9px;
    position: absolute;
    top: -9px;
    width: 0;
  }
  bottom: 4px;
  transform: rotate(45deg);
}
