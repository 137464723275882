@mixin wrapper {
  display: flex;
  flex: 1;
  box-sizing: border-box;
  min-height: 100px;
  width: 100%;
  border-radius: 4px;
  align-items: flex-end;
  margin-top: 25px;
  padding: 25px;
}

@mixin galleryWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 191px;
  height: 114px;
  border-radius: 5px;
  margin: 5px;
}
