@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";
.cell {
  button {
    &:disabled {
      background-color: transparent;
      color: $medium-grey;
    }
    :hover:not(:disabled) {
      background-color: $medium-grey;
      color: $black;
    }
    @include small-regular;
    background-color: transparent;
    border: none;
    border-radius: rem-calc(4);
    color: $black;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .buttonFocused {
    :focus {
      background-color: $medium-grey;
      color: $black;
    }
  }
  .buttonHidden {
    :disabled {
      color: transparent;
    }
  }
  .buttonSelected {
    background-color: $primary;
    color: $white;
  }
  .cell {
    border: rem-calc(2) solid transparent;
    height: rem-calc(28);
    margin: 0;
    padding: 0;
    width: rem-calc(28);
  }
  .cellWithinRange {
    background-color: $active-fill;
  }
}
