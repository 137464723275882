@import "../../../../shared/src/sass/abstracts/functions";

.row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.wide {
  display: flex;
  flex-direction: column;
  margin-left: rem-calc(20);
  flex-grow: 1;
}
