@import "../../../../../sass/abstracts/mixins";

.historyDateCell {
  display: flex;
  flex-direction: column;

  & span {
    line-height: rem-calc(24);
  }
}

.time {
  color: $dark-grey-blue;
}
