@import "../../sass/abstracts/mixins";

.wrapper {
  width: 264px;
  height: 100%;
  font-weight: 500 !important;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 0.2rem;
  overflow-y: scroll;

  @media print {
    display: none;
  }
}

.privacyTermsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  padding-bottom: 10px;

  @media (max-width: $max-tablet) {
    border-top: 1px solid #ebebeb;
    bottom: 0;
    justify-content: center;
    padding: 10px;
    position: absolute;
    width: 100%;
  }
}

.link {
  text-decoration: none;
  font-size: 0.75rem;
  color: $medium-grey;
}

.separator {
  color: $dark-grey;
  font-size: 24px;
  margin: 0 0.5rem;
}
