@import "../../sass/abstracts/mixins";

.privacy {
  @media (max-width: $max-tablet) {
    font-size: rem-calc(14);
  }
}

.pageHeader {
  font-weight: 400;
  font-size: rem-calc(36);
  letter-spacing: 0.63px;

  @media (max-width: $max-tablet) {
    font-size: rem-calc(20);
    letter-spacing: 0.26px;
  }
}

.lastUpdated {
  font-size: rem-calc(14);
  font-style: italic;
}

.textBlock {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sectionHeader {
  font-weight: 400;
  font-size: rem-calc(20);
  letter-spacing: 0.26px;
  margin-bottom: 1rem;

  @media (max-width: $max-tablet) {
    font-size: rem-calc(18);
  }
}

.subheader {
  // font-weight: 400;
  font-size: rem-calc(18);
  margin-bottom: 1.5rem;

  @media (max-width: $max-tablet) {
    font-size: rem-calc(16);
  }
}

.listHeading {
  margin-bottom: 1.5rem;
}

.listBlock {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.signatureWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
