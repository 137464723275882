@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  // TODO possibly find a way to lower z-index here (temporary fix to use modal from a drawer) - MW
  z-index: 1201;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: $backdrop-color;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  width: 674px;
  height: auto;
  background-color: $white;
  box-shadow: 0px 16px 24px $box-shadow;
  border: 1px solid $light-grey;
  border-radius: 4px;
  margin: 15% auto;
  padding: 34px;
  // TODO possibly find a way to lower z-index here (temporary fix to use modal from a drawer) - MW
  z-index: 1202;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.headerText {
  @include font-size(24);
  font-weight: 500;
  line-height: rem-calc(32);
  margin: 0;
}

.description {
  @include font-size(20);
  line-height: rem-calc(28);
  margin-top: 0;
  margin-bottom: 20px;
}

.content {
  margin-bottom: 20px;
}

.alert {
  margin-bottom: 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.cancelButton, .otherButtons {
  margin-right: 10px;
}

.modalPromptCloseButton {
  border: 0;
  background: none;
  color: $dark-grey;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  position: absolute;
  top: 24px;
  right: 23px;
}