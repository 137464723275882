@import "shared/src/sass/abstracts/variables";

.arrow {
  height: 15px;
  position: absolute;
  width: 15px;

  &::after {
    border-bottom: 7px solid $white;
    border-left: 7px solid transparent;
    border-right: 7px solid $white;
    border-top: 7px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
  }

  &::before {
    border-bottom: 8px solid rgba($black, 0.1);
    border-left: 8px solid transparent;
    border-right: 8px solid rgba($black, 0.1);
    border-top: 8px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
  }
}

.popper[data-popper-placement*="bottom"] .arrow {
  top: 4px;

  &::after,
  &::before {
    transform: rotate(-135deg);
  }

  &:after {
    left: 1px;
    bottom: -1px;
  }

  &:before {
    bottom: -2px;
  }
}

.popper[data-popper-placement*="left"]  .arrow {
  right: 4px;

  &::after,
  &::before {
    transform: rotate(-45deg);
  }

  &:after {
    left: -1px;
    top: 1px;
  }

  &:before {
    left: -2px;
  }
}

.popper[data-popper-placement*="right"]  .arrow {
  left: 4px;

  &::after,
  &::before {
    transform: rotate(135deg);
  }

  &:after {
    right: -1px;
    top: 1px;
  }

  &:before {
    right: -2px;
  }
}

.popper[data-popper-placement*="top"]  .arrow {
  bottom: 4px;

  &::after,
  &::before {
    transform: rotate(45deg);
  }

  &:after {
    left: 1px;
    top: -1px;
  }

  &:before {
    top: -2px;
  }
}
