.tooltip {
  align-items: center;
  background: white;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 2px;
  display: flex;
  padding: 5px 9px;
  white-space: pre;

  &ColorSwatch {
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }
}
