@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.s16 {
  font-size: 16px;
}
.statusIndicatorError {
  width: 0.525rem;
  height: 0.525rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  background: $error;
}
.statusIndicatorWarning {
  width: 0.525rem;
  height: 0.525rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  background: $warning;
}

.validationBannerError {
  background: $error-fill;
  border: $error;
}
.validationBannerWarning {
  background: $warning-fill;
  border: $warning;
}
.validationStatusError {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  color: $error;
}
.validationStatus {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.validationLinkError {
  text-align: left;
  color: $error;
}
.validationLink {
  text-align: left;
}

.fieldErrorWrapper {
  border: 2px solid $error;
  background: $error-fill;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.fieldError {
  color: $error;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}
