@import "../../sass/abstracts/mixins";

.iconBox {
  align-items: center;
  background-color: $light-grey;
  border-radius: 4px;
  display: flex;
  height: 34px;
  justify-content: center;
  width: 34px;
}

.icon {
  font-size: 22px;
}
