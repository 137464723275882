@import "../../sass/abstracts/functions";
@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.calendarIcon {
  fill: $dark-grey-blue;
  height: rem-calc(24);
  margin-right: rem-calc(8);
  width: rem-calc(24);

  &.calendarIconActive {
    fill: $primary;
  }
}
.dateButton {
  @include font-body-regular;
  @include focus-indicator-disappear;
  background-color: $white;
  border-color: $grey-blue;
  border-radius: rem-calc(4);
  border-style: solid;
  border-width: 1px;
  color: $black;
  display: inline-flex;
  margin: 0;
  padding: rem-calc(16);

  &.disabled {
    background-color: $light-grey;
  }

  &.dateButtonActive {
    @mixin active-border-overlap-appear {
      box-shadow: 0 0 0 1px $primary;
      border-color: $primary;
    }
  }

  &.dateButtonKeyboardFocused {
    &:focus {
      @mixin focus-indicator-overlap-appear {
        border-color: $focus-indicator-outline;
        box-shadow: 0 0 0 rem-calc(3) $focus-indicator-outline;
      }
    }
    outline: none;
  }
  
  &.dateButtonMouseFocused {
    transition: none;
  }
}
