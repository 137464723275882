@charset "UTF-8";

@import "shared/src/sass/abstracts/variables";
@import "shared/src/sass/abstracts/functions";

.multiInput {
  :nth-child(1n) .MuiInput-root {
    display: none;
  }
  :nth-child(1n) ul {
    border: none;
  }
}

.answerOptionTitleBox {
  flex: 1;
}

.answerOptionItem {
  list-style-type: none;
  display: flex;
  align-items: center;
  border: 1px solid $light-grey;
  border-radius: rem-calc(4);
  padding: 0.5rem;
  margin: 0.5rem;
  cursor: default;
}

.answerOptionTitle {
  font-size: rem-calc(14);
  color: $black;
}

.answerOptionSubTitle {
  font-size: rem-calc(12);
  margin: 2.4px 0 0;
}

.endAdornment {
  cursor: pointer;

  & i {
    position: relative;
    top: 2px;
  }
}

.multiInputContent {
  border-radius: rem-calc(4);
  border: 1px solid $dark-grey;
  background: $background;

  &.error {
    border-color: $error;
    background: $error-fill;
  }
}

.multiInputList {
  padding: rem-calc(12);
  margin: 0;
}

